import React from "react"
import { graphql, navigateTo } from "gatsby"
import { Flex, Button, Text } from "rebass"
import { Layout, SEO, PostItem } from "../components/"
import Grid from "../components/extend/Grid"
import { capitalizeString } from "@utils"

const Categories = ({ data, location }) => {
  const pathCategory = location.pathname === '/categories' ? 'all' : location.pathname.replace('/categories/', '')
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const categories = [...new Set(posts.map(({node}) => node.frontmatter.category))]
  return (
    <Layout location={location} title={siteTitle} posts={posts}>
      <SEO title="Categories" />
      <Text as="h1" mt={'60px'} mb={10} textAlign={'center'}>{pathCategory && categories.includes(pathCategory) ? capitalizeString(pathCategory) : 'All'}</Text>
      <Flex justifyContent={'center'} flexDirection={['column', 'row']}>
        <Button className="item-link capitalize" mx={10} onClick={() => navigateTo('categories')}>all</Button>
        { categories ? 
          categories.map((category, idx) => {
            return (
              <Button className="item-link capitalize" key={idx} mx={10} onClick={() => navigateTo(`categories/${category}`)}>{category}</Button>
            )
          })
          :
          null
        }
      </Flex>
      <Grid gap={1} columns={[3, 1, 2]}>
        {
          pathCategory !== 'all' && categories.includes(pathCategory) ?
          posts.filter(({node}) => node.frontmatter.category === pathCategory).map(({node}) => {
            return (
              <PostItem key={`${node.id}`} post={node}></PostItem>
            )
          })
          : 
          posts.map(({node}) => {
            return (
              <PostItem key={`${node.id}`} post={node}></PostItem>
            )
          })
        }
      </Grid>
    </Layout>
  )
}

export default Categories

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {fileAbsolutePath: {regex: "/index/"}}) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            category
            image
          }
        }
      }
    }
  }
`
